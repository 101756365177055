import { UserProfile, UserWorkoutSession } from 'fitbeat.gym-core';
import {
  Gym,
  IAccountComment, LeadStatus,
  TimeSlotType, UserAccountDetail, UserAccountScheduleActionInfo,
} from 'fitbeat.models';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { SortingRule } from 'react-table';
import 'react-table/react-table.css';
import { getMemberAccountScheduleAndWorkouts, toggleAddPauseModal } from '../accountActions/actions';
import { currentGymSelector } from '../adminSettings/selector';
import { IAppStore } from '../rootReducer';
import { Loading } from '../shared/loading';
import {
  getMemberAccount, toggleConfirmationModal, toggleEditMemberModal,
} from './actions';
import Invoices from './components/invoices';
import MemberDetailsOverview from './components/MemberDetailsOverview';
import MemberNameWithStatusColor from './components/MemberNameWithStatusColor';
import MemberPaymentsOverviewTable from './components/MemberPaymentsOverviewTable';
import MemberSummaryDetails from './components/MemberSummaryDetails';
import Subscriptions from './components/Subscriptions';
import WorkoutHistoryOverviewTable from './components/WorkoutHistoryOverviewTable';
import MemberComments from './memberComments';

import TabDisplay from '../shared/components/TabDisplay/TabDisplay';
import { Error } from '../shared/error';
import { toggleStripeKeyModal, toggleStripeSubscriptionsModal } from '../stripe/actions';
import { MemberDetailsAvatar } from './components/MemberDetailsAvatar';
import Merchandise from './components/Merchandise';
import { LeadStatusDropdown } from './LeadStatusDropdown';
import MemberFollowUpDate from './memberFollowUpDate';

interface IProps {
  error: string | null;
  isLoading: boolean;
  canViewSubscriptionDetails: boolean;
  accountScheduleLoading: boolean;
  UID: string;
  memberDetail: UserProfile;
  memberAccountData: UserAccountDetail;
  memberAccountShedule: UserAccountScheduleActionInfo;
  showStripeActions: boolean;
  sortOrder: SortingRule[];
  memberWorkoutHistory: UserWorkoutSession[];
  currentGym: Gym;
  memberComments: IAccountComment[];
  activeLeadStatus: LeadStatus;
  isError: boolean;
  toggleEditMemberModal(): void;
  toggleWorkoutHistory(): void;
  toggleStripeKeyModal(): void;
  getMemberAccount(uid: string): void;
  getMemberAccountScheduleAndWorkouts(uid: string, stripeAccountId: string): void;
}

class MemberDetails extends React.Component<IProps, any> {
  public reactTable: any;

  constructor(props: any) {
    super(props);
    this.reactTable = React.createRef();
    this.state = {
      isOpen: false,
      togglePaymentInfo: false,
      toggleInvoiceInfo: false,
      toggleWorkoutHistory: false,
    };
  }

  public async componentDidMount() {
    await this.props.getMemberAccount(this.props.UID);
    this.props.getMemberAccountScheduleAndWorkouts(this.props.UID, this.props.currentGym.accountId);
  }

  public toggleExtraInfo = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  public togglePaymentInfo = () => {
    this.setState({ togglePaymentInfo: !this.state.togglePaymentInfo });
  };

  public toggleInvoiceInfo = () => {
    this.setState({ toggleInvoiceInfo: !this.state.toggleInvoiceInfo });
  };

  public toggleWorkoutHistory = () => {
    this.setState({ toggleWorkoutHistory: !this.state.toggleWorkoutHistory });
  };

  public generateTabDisplay = (includeSubscription: boolean = false) => {
    let tabContents = [
      {
        tabTitle: 'Overview',
        tabContents: <MemberDetailsOverview UID={this.props.UID} />,
      },
      {
        tabTitle: 'Workouts',
        tabContents: (
          <WorkoutHistoryOverviewTable sortOrder={this.props.sortOrder} />
        ),
      },
    ];
    if (includeSubscription) {
      tabContents.splice(1, 0, {
        tabTitle: 'Subscription',
        tabContents: <Subscriptions uid={this.props.UID} />,
      });

      tabContents = [
        ...tabContents,
        {
          tabTitle: 'Invoices',
          tabContents: <Invoices uid={this.props.UID} />,
        },
        {
          tabTitle: 'Payments',
          tabContents: (
            <MemberPaymentsOverviewTable sortOrder={this.props.sortOrder} />
          ),
        },
        {
          tabTitle: 'Merchandise',
          tabContents: (<Merchandise uid={this.props.UID} />),
        },
      ]
    }
    return tabContents;
  };

  public handleMemberCommentConvert = (memberComment: string | any[]): any[] => {
    if (typeof memberComment === 'string') {
      return [{ comment: memberComment, date: null }];
    }
    if (!memberComment) {
      return [];
    }
    const sortedList = [...memberComment].sort(this.sortCommentOnDate);
    return sortedList;
  };

  public render() {
    const {
      memberDetail,
      memberAccountData,
      memberComments,
      UID,
      currentGym,
      isError,
      isLoading,
      canViewSubscriptionDetails,
    } = this.props;
    const userAccountInfo = memberAccountData && memberAccountData.userInfo;
    const hasAccessToAStudioGym = userAccountInfo?.hasAccessToAStudioGym;
    const commentsAsList = this.handleMemberCommentConvert(memberComments);
    if (!this.props.isLoading && !isError && memberDetail) {
      const memberStatus = (memberDetail.timeSlotType === TimeSlotType.None && hasAccessToAStudioGym)
        ? 'noTimeSlots'
        : memberDetail.currentPlanStatus;
      return (
        <div className='app-wrapper'>
          <div className='member-details-top'>
            <MemberNameWithStatusColor
              fullName={memberDetail.fullname}
              status={memberStatus}
            />
          </div>
          <div className='member-details-main-content-container'>
            <div className='member-details-left-content-container'>
              <MemberDetailsAvatar UID={UID} memberDetail={memberDetail} />
              <div className='lead-status-head-control-container'>
                <h1 className='lead-status-heading'>Lead Status:</h1>
                <LeadStatusDropdown
                  uid={this.props.UID}
                  activeLeadStatus={this.props.activeLeadStatus}
                />
              </div>

              <MemberSummaryDetails
                currentGym={currentGym}
                userAccountInfo={userAccountInfo}
                memberDetails={memberDetail}
              />
              <div className='summary-horizontal-separator' />
              <MemberFollowUpDate uid={this.props.UID} />
              <div className='comment-section-container'>
                <h1 className='comments-heading'>Comments</h1>
                <MemberComments UID={this.props.UID} />
                <div className='comments-list-container'>
                  {commentsAsList && commentsAsList.map((singleComment: any) => (singleComment.comment ? (
                    <div className='single-comment-container'>
                      <p className='single-container-comment-text'>{singleComment.comment}</p>
                      <p className='single-container-comment-date'>
                        {singleComment.date ? moment(singleComment.date).format('DD MMM LT') : '-'}
                      </p>
                    </div>
                  ) : null))}
                </div>
              </div>
            </div>
            <div className='member-details-right-content-container'>
              <TabDisplay
                tabItemsWithDisplay={this.generateTabDisplay(canViewSubscriptionDetails)}
                tabHeaderClass='nav nav-tabs tab-headers-container'
                tabContentClass='tab-content-container'
              />
            </div>
          </div>
        </div>
      );
    }
    if (isError && !isLoading) {
      return (
        <Error
          errorMessage={this.props.error || undefined}
        />
      );
    }
    return <Loading />;
  }

  private sortCommentOnDate = (a: IAccountComment, b: IAccountComment) => {
    if (new Date(a.date) < new Date(b.date)) {
      return 1;
    }
    if (new Date(a.date) > new Date(b.date)) {
      return -1;
    }
    return 0;
  };
}

const mapStateToProps = (state: IAppStore, props: any) => {
  const { members } = state;
  const { stripe } = state;
  const { isFetching, error } = members.view;
  const { memberAccountData, memberComments } = members.data;
  const { match: { params } } = props;
  const memberDetail = memberAccountData && memberAccountData.userInfo.profile;
  const { showStripeActions } = stripe.view;
  const { accountActions } = state;
  const { memberAccountShedule, memberWorkoutHistory } = accountActions.data;
  const { isFetching: accountScheduleLoading } = accountActions.view;
  const currentGym = currentGymSelector(state);
  const { canViewSubscriptionDetails } = state.adminSettings;

  return {
    isLoading: isFetching,
    isError: !!error,
    error,
    memberDetail,
    UID: params.uid,
    showStripeActions,
    toggleStripeSubscriptionsModal,
    memberAccountData,
    memberAccountShedule,
    memberWorkoutHistory,
    accountScheduleLoading,
    currentGym,
    memberComments,
    canViewSubscriptionDetails,
    activeLeadStatus: state.members.data.memberAccountData?.userInfo.profile.leadStatus,
  };
};

const mapDispatchToProps = {
  toggleStripeKeyModal,
  getMemberAccount,
  toggleEditMemberModal,
  toggleAddPauseModal,
  toggleConfirmationModal,
  getMemberAccountScheduleAndWorkouts,
};
export default connect(mapStateToProps, mapDispatchToProps)(MemberDetails);
