import { ILeaderboardInstance, IOrganisationConfig } from 'fitbeat.models';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { components } from 'react-select';
import { IAppStore } from '../../rootReducer';
import Dropdown from '../../shared/components/Dropdown';
import { updateUserTeamSelected } from '../leaderboardsSlice';
import { mapLeaderboardTeamsToOption } from './helpers';

interface IProps {
  uid: string;
  activeTeamId: string;
  selectedGymName: string | null;
  organisationConfig?: IOrganisationConfig;
  selectedLeaderboardTeam: string | null;
  selectedLeaderboardInstance: ILeaderboardInstance | null;
  leaderboardTeamsToListOptions: { value: string, label: string }[];
  mappedSelectedLeaderboardTeam: { value: string, label: string } | null;
  teamSelected: (teamDetails: {
    gym: string | null,
    leaderboardInstance: ILeaderboardInstance,
    uid: string,
    newTeam: string,
  }) => void;
}

class TeamsDropdownComponent extends Component<IProps> {
  public render() {
    const {
      activeTeamId,
      selectedLeaderboardTeam,
      selectedLeaderboardInstance,
      leaderboardTeamsToListOptions,
      mappedSelectedLeaderboardTeam,
      selectedGymName,
    } = this.props;
    const NoTeamText = selectedLeaderboardTeam ? 'Opted out' : null;
    return (
      activeTeamId
        ? (
          <Dropdown
            options={leaderboardTeamsToListOptions}
            value={mappedSelectedLeaderboardTeam}
            onChange={
              (selected: { label: string; value: string }) => {
                if (selectedLeaderboardInstance) {
                  this.props.teamSelected({
                    gym: selectedGymName,
                    leaderboardInstance: selectedLeaderboardInstance,
                    uid: this.props.uid,
                    newTeam: selected.value,
                  })
                }
              }
            }
            placeholder='— Select team —'
            className='lead-status select-container'
            classNamePrefix='teams lead-status select'
            components={{ Control: this.customControl }}
          />
        )
        : NoTeamText
    );
  }

  private customControl = ({ ...props }) => (
    <components.Control {...props}>
      {props.children}
    </components.Control>
  )
}

const mapDispatchToProps = (dispatch: any) => ({
  teamSelected: (teamDetails: {
    gym: string,
    leaderboardInstance: ILeaderboardInstance,
    uid: string,
    newTeam: string,
  }) => dispatch(updateUserTeamSelected({
    gym: teamDetails.gym,
    leaderboardInstance: teamDetails.leaderboardInstance,
    uid: teamDetails.uid,
    newTeam: teamDetails.newTeam,
  })),
});

const mapStateToProps = (state: IAppStore, props: IProps): Partial<IProps> => {
  const { leaderboards, adminSettings } = state;
  const {
    selectedLeaderboardConfig,
    selectedLeaderboardTeam,
    selectedLeaderboardInstance,
  } = leaderboards.data;
  const { selectedGymName } = adminSettings.data;
  const leaderboardTeamsToListOptions = selectedLeaderboardConfig?.teams ? [
    ...selectedLeaderboardConfig.teams,
  ].map((
    singleItem: string,
  ) => mapLeaderboardTeamsToOption(singleItem)) : [];

  const mappedSelectedLeaderboardTeam = props.activeTeamId
    ? mapLeaderboardTeamsToOption(props.activeTeamId) : undefined;

  return {
    selectedGymName,
    organisationConfig: state.members.data.organisationConfig,
    leaderboardTeamsToListOptions,
    mappedSelectedLeaderboardTeam,
    selectedLeaderboardTeam,
    selectedLeaderboardInstance,
  }
};

export const TeamsDropdown = connect(mapStateToProps, mapDispatchToProps)(TeamsDropdownComponent);
