import { Gym } from 'fitbeat.models';
import React from 'react';
import { MemberTableHeader } from '../members/components/memberTableHeader';
import { TeamsDropdown } from './components/teamsDropdown';

export const buildLeaderboardInstanceColumnsFromAccessor = (
  accessor: string,
  currentGym: Gym,
) => {
  switch (accessor) {
    case 'rank':
      return {
        Header: () => <MemberTableHeader header='Position' />,
        accessor,
        used: true,
        headerClassName: 'container-overflow-visible',
        sortable: true,
      };
    case 'name':
      return {
        Header: () => <MemberTableHeader header='Name' />,
        accessor,
        used: true,
        headerClassName: 'container-overflow-visible',
        sortable: true,
      };
    case 'lastname':
      return {
        Header: () => <MemberTableHeader header='Last Name' />,
        accessor,
        used: true,
        headerClassName: 'container-overflow-visible',
        sortable: true,
      };
    case 'email':
      return {
        Header: () => <MemberTableHeader header='Email' />,
        accessor,
        used: true,
        headerClassName: 'container-overflow-visible',
        sortable: true,
      };
    case 'value':
      return {
        Header: () => <MemberTableHeader header='Score' />,
        accessor,
        used: true,
        headerClassName: 'container-overflow-visible',
        sortable: true,
        Cell: ({ original }: any) => Math.round(original.value * 100) / 100,
      };
    case 'teamName':
      return {
        Header: () => <MemberTableHeader header='Team' />,
        accessor,
        used: true,
        headerClassName: 'container-overflow-visible',
        sortable: true,
        Cell: ({ original }: any) => {
          const defaultTeamName = original.currentTeam || '';
          return (
            <TeamsDropdown
              activeTeamId={defaultTeamName}
              uid={original.userProfileId}
            />
          );
        },
      };
    case 'memberDetails':
      return {
        Header: () => <MemberTableHeader header='Member Details' />,
        accessor,
        used: true,
        headerClassName: 'container-overflow-visible',
        sortable: false,
        filterAll: false,
        filterable: false,
        Cell: ({ original }: any) => {
          if (!original.userProfileId) {
            return null
          }
          const openUserDetails = () => {
            window.open(`/#/app/memberDetails/${original.userProfileId}`, '_blank'); return false;
          };
          return (
            <button
              onClick={openUserDetails}
              className='jr-btn-default-outlined'
              role='rowgroup'
            >
              View Details
            </button>
          );
        },
      };
    default:
      return {};
  }
};

export const leaderboardInstanceAccessors = [
  {
    accessor: 'rank',
    used: true,
    sortable: true,
    width: 80,
  },
  {
    accessor: 'name',
    used: true,
    sortable: true,
    width: 150,
  },
  {
    accessor: 'lastname',
    used: true,
    sortable: true,
    width: 150,
  },
  {
    accessor: 'email',
    used: true,
    sortable: true,
    width: 220,
  }, {
    accessor: 'value',
    used: true,
    sortable: true,
    width: 100,
  },
  {
    accessor: 'teamName',
    used: true,
    sortable: true,
    width: 150,
  },
  {
    accessor: 'memberDetails',
    used: true,
    sortable: true,
    width: 150,
  }];
